import { HttpErrorResponse, HttpRequest, HttpResponse } from '@angular/common/http'
import { inject } from '@angular/core'
import { LogService } from 'src/app/data/services/erp/log/log.service'
import { LogAuxiliaryData } from 'src/app/domain/models/erp/log/log-auxiliary-data.model'
import { LogAccountingEntryManagement } from './log-accounting-entry-management'
import { LogFinancialManagement } from './log-financial-management'
import { LogInternalMovementManagement } from './log-internal-movement-management'
import { LogOperationManagement } from './log-operation-rule-management'
import { LogOrderManagement } from './log-order-management'
import { LogParameterCenterManagement } from './log-parameter-center-management'

import { LogAccountsPlanManagement } from './log-accounts-plan-management'
import { LogEditLedgerManagement } from './log-edit-ledger-management'
import { LogFiscalNoteManagement } from './log-fiscal-note-management'
import { LogParticipantManagement } from './log-participant-management'
import { LogPeriodParameterManagement } from './log-period-parameter-management'
import { LogProductServiceManagement } from './log-product-service-management'
import { LogProductionOrderManagement } from './log-production-order-management'
import { LogReprocessManagement } from './log-reprocess-management'
import { LogRmsManagement } from './log-rms-management'
import { LogProductTreeManagement } from './log-product-tree-management'

export class LogManagement {

  private logService: LogService = inject(LogService)

  private logAccountingEntry: LogAccountingEntryManagement = new LogAccountingEntryManagement()
  private logAccountingPeriodManagement: LogPeriodParameterManagement = new LogPeriodParameterManagement()
  private logAccountsPlanManagement: LogAccountsPlanManagement = new LogAccountsPlanManagement()
  private logFinancialManagement: LogFinancialManagement = new LogFinancialManagement()
  private logInternalMovementManagement: LogInternalMovementManagement = new LogInternalMovementManagement()
  private logEditLedgerManagement: LogEditLedgerManagement = new LogEditLedgerManagement()
  private logOrderManagement: LogOrderManagement = new LogOrderManagement()
  private logOperationManagement: LogOperationManagement = new LogOperationManagement()
  private logParticipantManagement: LogParticipantManagement = new LogParticipantManagement()
  private logProductionOrderManagement: LogProductionOrderManagement = new LogProductionOrderManagement()
  private logProductServiceManagement: LogProductServiceManagement = new LogProductServiceManagement()
  private logParameterCenterManagement: LogParameterCenterManagement = new LogParameterCenterManagement()
  private logReprocessManagement: LogReprocessManagement = new LogReprocessManagement()
  private logFiscalNoteManagement: LogFiscalNoteManagement = new LogFiscalNoteManagement()
  private logRmsManagement: LogRmsManagement = new LogRmsManagement()
  private logProductTree: LogProductTreeManagement = new LogProductTreeManagement()

  public generateLog(request: HttpRequest<any>, response: HttpResponse<any> | HttpErrorResponse): void {
    let auxiliaryData: LogAuxiliaryData = new LogAuxiliaryData()

    const hasError: boolean = !(response instanceof HttpResponse)

    this.logService.auxiliaryDataSubject.subscribe((res: any) => auxiliaryData = res)

    if ((auxiliaryData.function !== null) && ((response.ok && !response.type) || response.type)) {
      if (!hasError) {
        this.logAccountingEntry.generateLog(request, (response as HttpResponse<any>), auxiliaryData)
        this.logAccountingPeriodManagement.generateLog(request, (response as HttpResponse<any>), auxiliaryData)
        this.logAccountsPlanManagement.generateLog(request, (response as HttpResponse<any>), auxiliaryData)
        this.logFinancialManagement.generateLog(request, (response as HttpResponse<any>), auxiliaryData)
        this.logEditLedgerManagement.generateLog(request, (response as HttpResponse<any>), auxiliaryData)
        this.logOrderManagement.generateLog(request, (response as HttpResponse<any>), auxiliaryData)
        this.logOperationManagement.generateLog(request, (response as HttpResponse<any>), auxiliaryData)
        this.logParticipantManagement.generateLog(request, (response as HttpResponse<any>), auxiliaryData)
        this.logProductionOrderManagement.generateLog(request, (response as HttpResponse<any>), auxiliaryData)
        this.logProductServiceManagement.generateLog(request, (response as HttpResponse<any>), auxiliaryData)
        this.logReprocessManagement.generateLog(request, (response as HttpResponse<any>), auxiliaryData)
        this.logParameterCenterManagement.generateLog(request, (response as HttpResponse<any>), auxiliaryData)
        this.logFiscalNoteManagement.generateLog(request, (response as HttpResponse<any>), auxiliaryData)
        this.logRmsManagement.generateLog(request, (response as HttpResponse<any>), auxiliaryData)
        this.logProductTree.generateLog(request, (response as HttpResponse<any>), auxiliaryData)
      }

      this.logInternalMovementManagement.generateLog(request, response, auxiliaryData)
    }

    auxiliaryData = new LogAuxiliaryData()
    this.logService.auxiliaryDataSubject.next(new LogAuxiliaryData())
  }
}
