import { TypeEnvironment } from 'src/app/domain/enums/application/environment.enum'
import { Environment } from 'src/app/domain/interfaces/application/environment.interface'

export const environment: Environment = {
  production: true,
  default: 'homologation environment',
  type: TypeEnvironment.HOMOLOGATION,
  version: require('./version.json').homologationVersion + '-HML',

  auroraURL: 'https://dev-aurora.linnks.com.br',

  apiURL: 'https://teste2.linnks.com.br',
  pyAnaliticsURL: 'https://devpyanalitics.octalink.com.br',
  socketURL: 'wss://devpysocket.octalink.com.br',
  octaremoteURL: 'https://devoctaremote.octalink.com.br',
  borealURL: 'https://devboreal.octalink.com.br',

  datadogApplicationId: 'not-found',
  datadogClientToken: 'not-found'
}