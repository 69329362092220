import { HttpRequest, HttpResponse } from '@angular/common/http'
import { inject } from '@angular/core'
import { ToastService } from 'src/app/data/services/components/toast/toast.service'
import { LogService } from 'src/app/data/services/erp/log/log.service'
import { ToastSeverity } from 'src/app/domain/enums/components/toast.enum'
import { LogAction, LogFunction, LogModule, LogScreen } from 'src/app/domain/enums/erp/log.enum'
import { Toast } from 'src/app/domain/interfaces/components/toast.interface'
import { LogAuxiliaryData } from 'src/app/domain/models/erp/log/log-auxiliary-data.model'

export class LogProductTreeManagement {
  private logService: LogService = inject(LogService)
  private toastService: ToastService = inject(ToastService)

  public generateLog(request: any, response: HttpResponse<any>, auxiliaryData: LogAuxiliaryData): void {
    if (response.status !== 200 && response.status !== 204) return
    if (response.body && response.body.error) return

    try {
      switch(auxiliaryData.function) {
        case LogFunction.SAVE_PRODUCT_TREE:
          this.generateLogSaveProductTree(request, response)
        break
        case LogFunction.EDIT_PRODUCT_TREE:
          this.generateLogSaveProductTree(request, response)
        break
        case LogFunction.DELETE_PRODUCT_TREE:
          this.generateLogDeleteProductTree(request, response, auxiliaryData.data)
        break
        case LogFunction.DUPLICATE_PRODUCT_TREE:
          this.generateLogDuplicateProductTree(request, response, auxiliaryData.data)
        break
        default:
          this.toastService.sendToast({ severity: ToastSeverity.ERROR, message: 'Função de log não reconhecida!' } as Toast)
        break
      }
    } catch (error) {
      this.toastService.sendToast({ severity: ToastSeverity.ERROR, message: 'Erro ao gerar log: ' + error } as Toast)
    }
  }

  private generateLogSaveProductTree(request: HttpRequest<any>, response: HttpResponse<any>): void {
    const registerId = request.body.tipo === 'new' ? response.body?.valor?.[0]?.data?.id || null : request.body.idItem

    this.logService.create(
      LogModule.REGISTRATION,
      LogScreen.PRODUCT_TREE,
      request.urlWithParams,
      request.body.tipo === 'new' ? LogAction.CREATE : LogAction.EDIT,
      registerId,
      request.body.itemPaiDescricao.split('-')[0].trim(),
      `A árvore de produto ${request.body.itemPaiDescricao} - ${request.body.descricao} foi ${request.body.tipo === 'new' ? 'criada' : 'alterada'}.`, 
      request.body 
    )
  }
  
  private generateLogDeleteProductTree(request:  HttpRequest<any>, response: HttpResponse<any>, auxiliaryData: any): void {
    this.logService.create(
      LogModule.REGISTRATION,
      LogScreen.PRODUCT_TREE,
      request.urlWithParams,
      LogAction.DELETE,
      auxiliaryData.id,
      auxiliaryData.codigo,
      `A árvore de produto ${auxiliaryData.codigo} - ${auxiliaryData.descricao} - ${auxiliaryData.descricaoBom} foi excluída.`, 
      request.body 
    )
  }

  private generateLogDuplicateProductTree(request:  HttpRequest<any>, response: HttpResponse<any>, auxiliaryData: any): void {
    this.logService.create(
      LogModule.REGISTRATION,
      LogScreen.PRODUCT_TREE,
      request.urlWithParams,
      LogAction.CREATE,
      response.body?.valor?.[0]?.data?.id || null,
      auxiliaryData.parentItemDescription.split('-')[0].trim(),
      `A árvore de produto ${auxiliaryData.codigo} - ${auxiliaryData.descricao} - ${auxiliaryData.descricaoBom} foi clonada com o item ${auxiliaryData.parentItemDescription}.`,
      request.body 
    )
  }
}

